// SearchForm.js
import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  OutlinedInput,
  Box,
  Grid,
  CircularProgress,
  IconButton,
  Tooltip,
  InputAdornment
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';

  const SearchForm = ({
    onSearch,
    currentPage,
    pageSize,
    setIsLoading,
    levelCounts,
    query,
    setQuery,
    selectedLevels,
    setSelectedLevels,
    selectedCategory,
    setSelectedCategory,
  }) => {
    const [isSearching, setIsSearching] = useState(false);

  
    const handleCategoryChange = (event) => {
      setSelectedCategory(event.target.value);
    };

  const handleLevelChange = (event) => {
    setSelectedLevels(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSearching(true);
      setIsLoading(true);
      await onSearch(query, selectedLevels, selectedCategory, 1);
    } catch (error) {
      console.error('Error fetching CVs:', error);
      onSearch('', [], '', false, 1);
    } finally {
      setIsSearching(false);
      setIsLoading(false);
    }
  };

  const sortedLevelCounts = Object.entries(levelCounts).sort(([levelA], [levelB]) => {
    if (levelA === 'NA') return 1;
    if (levelB === 'NA') return -1;
    return 0;
  });

  return (
    <Box sx={{ mb: 4 }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={7}>
          <TextField
            label="Search Query"
            variant="outlined"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip
                    title = 'Separate keywords with commas " , " to retrieve CVs that meet all specified conditions (intersection: CVs that contain all the keywords). Use " + " to retrieve CVs that meet any of the specified conditions (union: CVs that contain at least one of the keywords).'
                    placement="top"
                  >
                    <IconButton edge="start" sx={{ color: '#87CEFA' }}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <InputLabel id="category-select-label">Category</InputLabel>
              <Select
                labelId="category-select-label"
                id="category-select"
                value={selectedCategory}
                onChange={handleCategoryChange}
                label="Category"
              >
                <MenuItem value="">All categories</MenuItem>
                <MenuItem value="Potential">Potential</MenuItem>
                <MenuItem value="Prequalified">Prequalified</MenuItem>
                <MenuItem value="Qualified">Qualified</MenuItem>
                <MenuItem value="Engaged">Engaged</MenuItem>
                <MenuItem value="On Hold">On Hold</MenuItem>
                <MenuItem value="Terminated">Terminated</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <InputLabel id="level-select-label">Levels</InputLabel>
              <Select
                labelId="level-select-label"
                id="level-select"
                multiple
                value={selectedLevels}
                onChange={handleLevelChange}
                input={<OutlinedInput label="Levels" />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      width: 200,
                    },
                  },
                }}
              >
                {sortedLevelCounts.map(([level, count]) => (
                  <MenuItem key={level} value={level}>
                    {level} ({count})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Button
              type="submit"
              variant="contained"
              startIcon={isSearching ? <CircularProgress size={24} /> : <SearchIcon />}
              disabled={isSearching}
              sx={{
                background: '#37B6F6',
                color: 'rgb(255, 255, 255)',
              }}
            >
              {isSearching ? 'SEARCH' : 'Search'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default SearchForm;