import React, { useState, useEffect } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { styled } from '@mui/material/styles';
import { Divider } from '@mui/material';
import {
  Button,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Grid,
  List,
  ListItem,
  ListItemText,
  Box,
  Alert,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TableContainer,
  Paper,
  TableSortLabel,
  Typography,
  CircularProgress,
  Tooltip,
  InputAdornment,
  useMediaQuery,
  useTheme
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { runEvaluation, fetchEvaluationFiles, fetchEvaluationScores, downloadCv } from '../api';



const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)',
  borderRadius: theme.spacing(1),
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: {
    height: 'auto',
    maxHeight: 'calc(100vh - 64px)', // Adjust this value as needed
  },
  [theme.breakpoints.up('md')]: {
    height: 'calc(100vh - 120px)',
    overflowY: 'auto',
  },
}));

const StyledTableHeader = styled(TableRow)(({ theme }) => ({
  '& th': {
    backgroundColor: "#4c7a92",
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    padding: theme.spacing(0.02),
    position: 'sticky',
    top: 0,
    zIndex: 1,
    textAlign: 'center',
    fontSize: '0.70rem',
    borderRight: 'none',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  '& td': {
    padding: theme.spacing(0.4),
    borderBottom: `1px solid ${theme.palette.divider}`,
    textAlign: 'center',
    wordWrap: 'break-word',
    maxWidth: '140px',
    fontSize: '0.68rem',
  },
}));

const StyledSidebar = styled(Box)(({ theme }) => ({
  width: '25%',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.grey[200],
  borderRadius: theme.spacing(0),
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  '& .MuiTextField-root': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    '& .MuiInputBase-input': {
      padding: theme.spacing(1.5),
      fontSize: '0.75rem',
    },
    '& .MuiInputLabel-root': {
      fontSize: '0.75rem',
    },
  },
  '& .MuiSelect-root': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    '& .MuiSelect-select': {
      padding: theme.spacing(0.5),
      fontSize: '0.75rem',
    },
  },
  '& .MuiFormControl-root': {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    '& .MuiInputBase-root': {
      fontSize: '0.75rem',
    },
  },
  '& .MuiButton-root': {
    padding: theme.spacing(0.5),
    fontSize: '0.75rem',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  '& .MuiGrid-item': {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(0.5),
  },
  '& .MuiCheckbox-root': {
    '& .MuiSvgIcon-root': {
      fontSize: '0.875rem',
    },
  },
}));

const StyledEvaluationsList = styled(List)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(0.5),
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.05)',
  overflow: 'auto',
  '& .MuiListItem-root': {
    backgroundColor: '#F4F6F6',
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    '& .MuiListItemText-root': {
      '& .MuiTypography-root': {
        fontSize: '0.75rem',
      },
    },
    '& .MuiIconButton-root': {
      padding: theme.spacing(0.25),
      '& .MuiSvgIcon-root': {
        fontSize: '1rem',
      },
    },
    '&.Mui-selected': {
      backgroundColor: "#e1effa",
    },
    '&:hover': {
        backgroundColor: theme.palette.action.selected,
    },
  },
}));

const EvaluationsPage = ({
  evaluationFiles,
  setEvaluationFiles,
  levelCounts,
  selectedEvaluation,
  setSelectedEvaluation,
  evaluationScores,
  setEvaluationScores,
  isLoadingEvaluation,
  setIsLoadingEvaluation,
}) => {
  const [jobTitle, setJobTitle] = useState('');
  const [query, setQuery] = useState('');
  const [level, setLevel] = useState('sharepoint');
  const [certificates, setCertificates] = useState('');
  const [numCvs, setNumCvs] = useState('Relevant');
  const [requestFailed, setRequestFailed] = useState(false);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('score');
  const [category, setCategory] = useState('');
  const [noCVsFound, setNoCVsFound] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  const sortedEvaluationScores = React.useMemo(() => {
    if (orderBy) {
      const comparator = (a, b) => {
        const valueA = a[orderBy];
        const valueB = b[orderBy];
        if (valueA === undefined && valueB === undefined) {
          return 0;
        }
        if (valueA === undefined) {
          return 1;
        }
        if (valueB === undefined) {
          return -1;
        }
        return order === 'asc'
          ? valueA.localeCompare(valueB, undefined, { numeric: true })
          : valueB.localeCompare(valueA, undefined, { numeric: true });
      };
      return [...evaluationScores].sort(comparator);
    }
    return evaluationScores;
  }, [evaluationScores, order, orderBy]);

  useEffect(() => {
    if (evaluationFiles.length > 0) {
      if (selectedEvaluation) {
        const selectedFile = evaluationFiles.find((file) => file.file_name === selectedEvaluation.file_name);
        if (selectedFile) {
          setSelectedEvaluation(selectedFile);
        } else {
          handleEvaluationClick(evaluationFiles[0]);
        }
      } else {
        handleEvaluationClick(evaluationFiles[0]);
      }
    } else {
      setSelectedEvaluation(null);
      setEvaluationScores([]);
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setJobTitle('');
    setQuery('');
    setCertificates('');
    setLevel('sharepoint');
    setNumCvs('Relevant');
    setCategory('');
    setIsLoadingEvaluation(true);
  
    try {
      await runEvaluation(query, jobTitle, certificates.trim() ? certificates : '', level, numCvs, category);

      const updatedEvaluationFiles = await fetchEvaluationFiles();
      setEvaluationFiles(updatedEvaluationFiles);
      if (updatedEvaluationFiles.length > 0) {
        handleEvaluationClick(updatedEvaluationFiles[0]);
        
      }
    } catch (error) {
      if (error.message === 'No relevant CVs found') {
        setIsLoadingEvaluation(false);
        setNoCVsFound(true);
        setTimeout(() => {
          setNoCVsFound(false);
        }, 2000);

      } 
      else {
        console.error('Error running evaluation:', error);
        setIsLoadingEvaluation(false);
        setRequestFailed(true);
        setTimeout(() => {
          setRequestFailed(false);
        }, 2000);
      }
    } 
    finally {
      setIsLoadingEvaluation(false);
    }
  };

  const handleEvaluationClick = async (evaluation) => {
    setSelectedEvaluation(evaluation);
    try {
      const compositeKey = `${evaluation.job_title}_${evaluation.level}_${evaluation.category}_${evaluation.timestamp}`;
      const scores = await fetchEvaluationScores(compositeKey);
      setEvaluationScores(scores);
    } catch (error) {
      console.error('Error fetching evaluation scores:', error);
    }
  };

  const handleDownload = async (fileName, level) => {
    try {
      const url = await downloadCv(fileName, level);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error downloading CV:', error);
    }
  };
  

  return (
    //<Box sx={{ display: 'flex', flexDirection: 'row', height: 'calc(100vh - 64px)', mt: 8, overflow: 'hidden' }}>
    <Box sx={{
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      height: isMobile ? 'auto' : 'calc(100vh - 64px)',
      mt: 8,
      overflow: 'auto'
    }}>
      <StyledSidebar sx={{
        width: isMobile ? '100%' : '25%',
        height: isMobile ? 'auto' : '100%',
        overflow: 'visible'
      }}>
        {/* Sidebar */}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1} sx={{ mt:2}}>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <Select value={level} onChange={(e) => setLevel(e.target.value)} required>
                      <MenuItem value="sharepoint">Sharepoint</MenuItem>
                      {Object.entries(levelCounts)
                        .sort(([level1], [level2]) => {
                          if (level1 === "NA") return 1;
                          if (level2 === "NA") return -1;
                          return level1.localeCompare(level2);
                        })
                        .map(([level, count]) => (
                        <MenuItem key={level} value={level}>
                          {level} ({count})
                        </MenuItem>
                      ))}
                      <MenuItem value="All levels">All Levels</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <Select
                      labelId="category-select-label"
                      id="category-select"
                      value={category}
                      onChange={handleCategoryChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'category' }}
                      disabled={level === 'sharepoint'}
                    >
                      <MenuItem value="">All Categories</MenuItem>
                      <MenuItem value="Potential">Potential</MenuItem>
                      <MenuItem value="Prequalified">Prequalified</MenuItem>
                      <MenuItem value="Qualified">Qualified</MenuItem>
                      <MenuItem value="Engaged">Engaged</MenuItem>
                      <MenuItem value="On Hold">On Hold</MenuItem>
                      <MenuItem value="Terminated">Terminated</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <Select
                      labelId="num-cvs-label"
                      value={numCvs}
                      onChange={(e) => setNumCvs(e.target.value)}
                    >
                      <MenuItem value="Relevant">Relevant</MenuItem>
                      <MenuItem value="5">Top 5</MenuItem>
                      <MenuItem value="10">Top 10</MenuItem>
                      <MenuItem value="50">Top 50</MenuItem>
                      <MenuItem value="All">All CVs</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Relevant Keywords Filter"
                variant="outlined"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                fullWidth
                required={numCvs !== 'All'}
                disabled={numCvs === 'All'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title= 'Separate keywords with commas " , " to retrieve CVs that meet all specified conditions (intersection: CVs that contain all the keywords). Use " + " to retrieve CVs that meet any of the specified conditions (union: CVs that contain at least one of the keywords).'
                        placement="right"
                      >
                        <IconButton edge="end" sx={{ color: '#BFCDD4' }}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Job Title Experience"
                variant="outlined"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                fullWidth
                required
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Enter a job title keyword to retrieve the years of experience related to the specified job title."
                        placement="right"
                      >
                        <IconButton edge="end" sx={{ color: '#BFCDD4' }}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Certificates (comma-separated list)"
                variant="outlined"
                value={certificates}
                onChange={(e) => setCertificates(e.target.value)}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="If certificates are provided, they will be retrieved in the table if they exist. If no certificates are provided, the top 3 certificates will be retrieved."
                        placement="right"
                      >
                        <IconButton edge="end" sx={{ color: '#BFCDD4' }}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center" xs={{mt:1}}>
                <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isLoadingEvaluation} // Disable the button when isLoading is true
                  sx={{
                    background: '#3498DB',
                    color: 'rgb(255, 255, 255)',
                    '&.Mui-disabled': {
                      backgroundColor: '#ccc', // Change the disabled background color
                      color: '#777',
                    },
                  }}
                >
                  {isLoadingEvaluation && <CircularProgress size={20} />}
                  {isLoadingEvaluation ? 'Loading...' : 'Submit'}
                </Button>
                </Grid>
                {requestFailed && (
                  <Grid item>
                    <Alert
                      severity="error"
                      sx={{
                        fontSize: '0.75rem',
                        '& .MuiAlert-icon': {
                          fontSize: '1rem',
                          padding: 0,
                          marginRight: '0.5rem',
                        },
                        '& .MuiAlert-message': {
                          padding: 0,
                        },
                      }}
                    >
                      Request failed, please try again
                    </Alert>
                  </Grid>
                )}
                {noCVsFound && (
                  <Grid item>
                    <Alert
                      severity="warning"
                      sx={{
                        fontSize: '0.75rem',
                        '& .MuiAlert-icon': {
                          fontSize: '1rem',
                          padding: 0,
                          marginRight: '0.5rem',
                        },
                        '& .MuiAlert-message': {
                          padding: 0,
                        },
                      }}
                    >
                      No CVs found, please try again.
                    </Alert>
                  </Grid>
                )}
              </Grid>
              <Divider sx={{ my: 2, height: 4, backgroundColor: '#ccc'  }} />
            </Grid>
          </Grid>
        </form>
        <StyledEvaluationsList>
  {evaluationFiles.map((file, index) => (
    <ListItem
      key={index}
      button
      onClick={() => handleEvaluationClick(file)}
      selected={selectedEvaluation === file}
    >
      <ListItemText
        primary={
          <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1" fontWeight="bold">
                {file.job_title.replace(/_/g, ' ')}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {file.user} | {file.timestamp.replace(/_/g, ' ').replace(/-/g, ':')}
              </Typography>
            </Box>
            {file.level !== 'sharepoint' ? (
              <Typography variant="body2" color="textSecondary">
                <strong>Source: </strong> 
                {file.level !== 'All levels'
                  ? `Level ${file.level.replace(/_/g, ' ')}`
                  : file.level.replace(/_/g, ' ')}
              </Typography>
            ) : (
              <Typography variant="body2" color="textSecondary">
                <strong>Source:</strong> {file.level.replace(/_/g, ' ')}
              </Typography>
            )}
            {file.level !== 'sharepoint' && (
              <Typography variant="body2" color="textSecondary">
                <strong>Category:</strong> {file.category ? file.category.replace(/_/g, ' ') : 'All categories'}
              </Typography>
            )}
            <Typography variant="body2" color="textSecondary">
              <strong>Number of CVs:</strong> {file.num_cvs}
            </Typography>
            {file.num_cvs !== 'All' && (
              <Typography variant="body2" color="textSecondary">
                <strong>Keywords Filter:</strong> {file.query}
              </Typography>
            )}
            {file.certificates && (
              <Typography variant="body2" color="textSecondary">
                <strong>Certificates:</strong> {file.certificates}
              </Typography>
            )}
          </Box>
        }
      />
      <IconButton href={file.download_url} target="_blank" rel="noopener noreferrer">
        <DownloadIcon />
      </IconButton>
    </ListItem>
  ))}
</StyledEvaluationsList>
      </StyledSidebar>
      <Box sx={{
        width: isMobile ? '100%' : '75%',
        height: isMobile ? 'auto' : '100%',
        overflow: isMobile ? 'visible' : 'auto',
        mt: isMobile ? 1 : 0,
        ml: isMobile ? 1 : 2,
        mr: 1,
        pb: isMobile ? 4 : 0 // Add some padding at the bottom for mobile
      }}>
        {/* Evaluation scores table */}
        {selectedEvaluation && (
          <div>
            <StyledTableContainer component={Paper}>
            <Table>
              <TableHead>
                <StyledTableHeader>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'Name'}
                      direction={orderBy === 'Name' ? order : 'asc'}
                      onClick={() => handleSort('Name')}
                    >
                      Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'TotalYearsOfExperience'}
                      direction={orderBy === 'TotalYearsOfExperience' ? order : 'asc'}
                      onClick={() => handleSort('TotalYearsOfExperience')}
                    >
                      Total Years of Experience
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'YearsOfExperienceInJobTitle'}
                      direction={orderBy === 'YearsOfExperienceInJobTitle' ? order : 'asc'}
                      onClick={() => handleSort('YearsOfExperienceInJobTitle')}
                    >
                      {selectedEvaluation ? `${selectedEvaluation.file_name.split('_')[0].replace(/-/g, ' ')} Experience` : 'Years of Experience in Job Title'}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'RecentYearsOfExperienceInJobTitle'}
                      direction={orderBy === 'RecentYearsOfExperienceInJobTitle' ? order : 'asc'}
                      onClick={() => handleSort('RecentYearsOfExperienceInJobTitle')}
                    >
                      {selectedEvaluation ? `Recent ${selectedEvaluation.file_name.split('_')[0].replace(/-/g, ' ')} Experience` : 'Recent Years of Experience in Job Title'}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'LastExperienceStartYear'}
                      direction={orderBy === 'LastExperienceStartYear' ? order : 'asc'}
                      onClick={() => handleSort('LastExperienceStartYear')}
                    >
                      CV Last update
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'RecentTrainingInJobTitle'}
                      direction={orderBy === 'RecentTrainingInJobTitle' ? order : 'asc'}
                      onClick={() => handleSort('RecentTrainingInJobTitle')}
                    >
                      {selectedEvaluation ? `Recent Training in ${selectedEvaluation.file_name.split('_')[0].replace(/-/g, ' ')}` : 'Recent Training in Job Title '}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'Location'}
                      direction={orderBy === 'Location' ? order : 'asc'}
                      onClick={() => handleSort('Location')}
                    >
                      Location
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'BachelorDegree'}
                      direction={orderBy === 'BachelorDegree' ? order : 'asc'}
                      onClick={() => handleSort('BachelorDegree')}
                    >
                      Bachelor Degree
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'English'}
                      direction={orderBy === 'English' ? order : 'asc'}
                      onClick={() => handleSort('English')}
                    >
                      English
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                  <TableSortLabel
                      active={orderBy === 'OtherLanguages'}
                      direction={orderBy === 'OtherLanguages' ? order : 'asc'}
                      onClick={() => handleSort('OtherLanguages')}
                    >
                      Other Languages
                    </TableSortLabel>
                    </TableCell>

                  {evaluationScores.some((score) => score.hasOwnProperty('RequiredCertificates')) && (
                    <TableCell>
                      <TableSortLabel
                      active={orderBy === 'RequiredCertificates'}
                      direction={orderBy === 'RequiredCertificates' ? order : 'asc'}
                      onClick={() => handleSort('RequiredCertificates')}
                    >
                      Certificates
                    </TableSortLabel>
                      </TableCell>
                  )}
                  <TableCell>
                  <TableSortLabel
                      active={orderBy === 'Companies'}
                      direction={orderBy === 'Companies' ? order : 'asc'}
                      onClick={() => handleSort('Companies')}
                    >
                      Companies
                    </TableSortLabel>
                    </TableCell>
                </StyledTableHeader>
              </TableHead>
              <TableBody>
                {sortedEvaluationScores.map((score, index) => (
                  <StyledTableRow key={index}>
                    <TableCell>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {score.Name}
                      <IconButton onClick={() => handleDownload(score.FileName, score.Level)}>
                        <DownloadIcon />
                      </IconButton>
                    </Box>
                    </TableCell>
                    <TableCell>{Number(score.TotalYearsOfExperience)}</TableCell>
                    <TableCell>{Number(score.YearsOfExperienceInJobTitle)}</TableCell>
                    <TableCell>{Number(score.RecentYearsOfExperienceInJobTitle)}</TableCell>
                    <TableCell>{Number(score.LastExperienceStartYear)}</TableCell>
                    <TableCell>{score.RecentTrainingInJobTitle}</TableCell>
                    <TableCell>{score.Location}</TableCell>
                    <TableCell>{score.BachelorDegree}</TableCell>
                    <TableCell>{score.English}</TableCell>
                    <TableCell>
                      {score.OtherLanguages.split(',').map((language, index) => (
                        <div key={index}>{language.trim()}</div>
                      ))}
                    </TableCell>
                    {score.hasOwnProperty('RequiredCertificates') && (
                      <TableCell>{score.RequiredCertificates}</TableCell>
                    )}
                    <TableCell>{score.Companies}</TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
            </StyledTableContainer>
          </div>
        )}
      </Box>
    </Box>
  );
};

export default EvaluationsPage;