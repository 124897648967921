// App.js
import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import LoginForm from './components/LoginForm';
import Dashboard from './components/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import EvaluationsPage from './components/EvaluationsPage';
import AppHeader from './components/AppHeader';
import { searchCvs, fetchEvaluationFiles, fetchLevelCounts } from './api';
import useIdleTimer from './useIdleTimer';
import CVFormatPage from './components/CVFormatPage';
import ChangePasswordForm from './components/ChangePasswordForm';
import { useTheme } from '@mui/material/styles';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return sessionStorage.getItem('isLoggedIn') === 'true';
  });
  const [cvs, setCvs] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [query, setQuery] = useState('');
  const [selectedLevels, setSelectedLevels] = useState([]);
  const navigate = useNavigate();
  const [showTable, setShowTable] = useState(false);
  const [evaluationFiles, setEvaluationFiles] = useState([]);
  const [levelCounts, setLevelCounts] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [initialDataFetched, setInitialDataFetched] = useState(false);
  const [isLoadingFormat, setIsLoadingFormat] = useState(false);
  const [selectedEvaluation, setSelectedEvaluation] = useState(null);
  const [evaluationScores, setEvaluationScores] = useState([]);
  const [alert, setAlert] = useState(false); // Added alert state
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isLoadingEvaluation, setIsLoadingEvaluation] = useState(false); // Added
  const theme = useTheme();

  useEffect(() => {
    if (!initialDataFetched) {
      setIsLoading(true);
      Promise.all([
        searchCvs('', [], '', 1, pageSize), // Add empty category
        fetchLevelCounts(),
        fetchEvaluationFiles(),
      ])
        .then(([{ cvs, totalCount }, counts, files]) => {
          setCvs(cvs);
          setTotalCount(totalCount);
          setLevelCounts(counts);
          setEvaluationFiles(files);
          setShowTable(true);
          setInitialDataFetched(true);
        })
        .catch((error) => {
          console.error('Error fetching initial data:', error);
          setCvs([]);
          setTotalCount(0);
          setLevelCounts({});
          setEvaluationFiles([]);
          setShowTable(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [pageSize, initialDataFetched]);
  
  const handleLogin = async (response) => {
    if (response.success) {
      setIsLoggedIn(true);
  
      if (!initialDataFetched) {
        try {
          setIsLoading(true);
          const [{ cvs, totalCount }, counts, files] = await Promise.all([
            searchCvs('', [], '', 1, pageSize), // Add empty category
            fetchLevelCounts(),
            fetchEvaluationFiles(),
          ]);
          setCvs(cvs);
          setTotalCount(totalCount);
          setLevelCounts(counts);
          setEvaluationFiles(files);
          setShowTable(true);
          setInitialDataFetched(true);
        } catch (error) {
          console.error('Error fetching initial data:', error);
          setCvs([]);
          setTotalCount(0);
          setLevelCounts({});
          setEvaluationFiles([]);
          setShowTable(false);
        } finally {
          setIsLoading(false);
        }
      }
  
      navigate('/');
    } else if (response.message === 'Password expired') {
      // Redirect to change password page
      navigate('/change-password');
    } else {
      console.error('Login failed');
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    sessionStorage.removeItem('isLoggedIn');
    navigate('/login');
  };

  const handleSearch = async (newQuery, newLevels, newCategory, page = 1) => {
    try {
      const { cvs, totalCount } = await searchCvs(newQuery, newLevels, newCategory, page, pageSize);
      setCvs(cvs);
      setTotalCount(totalCount);
      setCurrentPage(page);
      setQuery(newQuery);
      setSelectedLevels(newLevels);
      setSelectedCategory(newCategory);
      setShowTable(true);
    } catch (error) {
      console.error('Error fetching CVs:', error);
      setCvs([]);
      setTotalCount(0);
      setShowTable(false);
    }
  };
  const updateCVData = (updatedCVs) => {
    setCvs(updatedCVs);
  };

  useIdleTimer(handleLogout, 5 * 60 * 1000);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden', backgroundColor: theme.palette.background.paper, minHeight: '100vh' }}>
      <Routes>
        <Route path="/login" element={<LoginForm onLogin={handleLogin} />} />
        <Route
          path="/"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <AppHeader onLogout={handleLogout} />
              <Dashboard
              onSearch={handleSearch}
              cvs={cvs}
              totalCount={totalCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              pageSize={pageSize}
              query={query}
              setQuery={setQuery}
              selectedLevels={selectedLevels}
              setSelectedLevels={setSelectedLevels}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              showTable={showTable}
              levelCounts={levelCounts}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              updateCVData={updateCVData}
            />
            </ProtectedRoute>
          }
        />
        <Route
          path="/evaluations"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <AppHeader onLogout={handleLogout} />
              <EvaluationsPage
                evaluationFiles={evaluationFiles}
                setEvaluationFiles={setEvaluationFiles}
                levelCounts={levelCounts}
                selectedEvaluation={selectedEvaluation}
                setSelectedEvaluation={setSelectedEvaluation}
                evaluationScores={evaluationScores}
                setEvaluationScores={setEvaluationScores}
                isLoadingEvaluation={isLoadingEvaluation}
                setIsLoadingEvaluation={setIsLoadingEvaluation} 
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/format"
          element={
            <ProtectedRoute isLoggedIn={isLoggedIn}>
              <AppHeader onLogout={handleLogout} />
              <CVFormatPage
                isLoadingFormat={isLoadingFormat}
                setIsLoadingFormat={setIsLoadingFormat}
                alert={alert}
                setAlert={setAlert}
              />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to={isLoggedIn ? '/' : '/login'} />} />
        <Route
          path="/change-password"
          element={
            <>
              <ChangePasswordForm />
            </>
          }
        />
      </Routes>
    </Box>
  );
}

export default App;