import { useEffect, useRef } from 'react';

const useIdleTimer = (onIdle, idleTime) => {
  const idleTimer = useRef(null);

  useEffect(() => {
    const resetTimer = () => {
      if (idleTimer.current) {
        clearTimeout(idleTimer.current);
      }
      idleTimer.current = setTimeout(onIdle, idleTime);
    };

    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keypress', resetTimer);

    return () => {
      if (idleTimer.current) {
        clearTimeout(idleTimer.current);
      }
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keypress', resetTimer);
    };
  }, [onIdle, idleTime]);
};

export default useIdleTimer;