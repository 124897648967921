// Dashboard.js
import React from 'react';
import { Container, Box, CircularProgress } from '@mui/material';
import SearchForm from './SearchForm';
import CVTable from './CVTable';

const Dashboard = ({
  onSearch,
  cvs,
  totalCount,
  currentPage,
  setCurrentPage,
  pageSize,
  query,
  selectedLevels,
  selectedCategory,
  setSelectedCategory,
  showTable,
  levelCounts,
  isLoading,
  setIsLoading,
  setQuery,
  setSelectedLevels,
  updateCVData,
}) => {
  const handlePageChange = async (newPage) => {
    try {
      setIsLoading(true);
      await onSearch(query, selectedLevels, selectedCategory, newPage);
      setCurrentPage(newPage);
    } catch (error) {
      console.error('Error fetching CVs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 64px)', mt: 8, overflow: 'auto' }}>
      <Container maxWidth="xl" sx={{ mt: 3.5 }}>
        <SearchForm
          onSearch={onSearch}
          currentPage={currentPage}
          pageSize={pageSize}
          setIsLoading={setIsLoading}
          levelCounts={levelCounts}
          query={query}
          setQuery={setQuery}
          selectedLevels={selectedLevels}
          setSelectedLevels={setSelectedLevels}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, alignItems: 'center', height: '60vh' }}>
            <CircularProgress size={48} />
          </Box>
        ) : showTable && (
          <CVTable
            cvs={cvs}
            totalCount={totalCount}
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={handlePageChange}
            updateCVData={updateCVData}
          />
        )}
      </Container>
    </Box>
  );
};

export default Dashboard;