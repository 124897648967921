// CVFormatPage.js
import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, CircularProgress, Paper, Alert } from '@mui/material';
import { formatCv } from '../api';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDropzone } from 'react-dropzone';

const CVFormatPage = ({
  isLoadingFormat,
  setIsLoadingFormat,
  alert,
  setAlert,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [abortController, setAbortController] = useState(null);

  useEffect(() => {
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [abortController]);

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles[0]);
      setAlert(false);
      handleFormatCv(acceptedFiles[0]);
    } else if (rejectedFiles.length > 0) {
      setAlert({ type: 'error', message: 'Format not supported. The only formats supported are .doc, .pdf, .docx, and .rtf.' });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/pdf': ['.pdf'],
      'application/rtf': ['.rtf'],
      'application/doc': ['.doc'],
    },
    multiple: false,
  });

  const handleFormatCv = async (file) => {
    setIsLoadingFormat(true);
    setAlert(false);
  
    const controller = new AbortController();
    setAbortController(controller);
  
    try {
      const formattedCvData = await formatCv(file, controller.signal);
      downloadCv(formattedCvData, file.name);
      setAlert({ type: 'success', message: 'CV format downloaded successfully.' });
    } catch (error) {
      if (error.name !== 'AbortError') {
        setAlert({ type: 'error', message: 'Error downloading CV format. Please try again.' });
      }
    } finally {
      setIsLoadingFormat(false);
    }
  };

  const downloadCv = (formattedCvData, originalFileName) => {
    const fileName = originalFileName.split('.').slice(0, -1).join('.');
    const formattedFileName = `${fileName}_Arteak_format.docx`;

    const url = window.URL.createObjectURL(new Blob([formattedCvData], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', formattedFileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 25 }}>
      <Paper elevation={3} sx={{ p: 4, textAlign: 'center', backgroundColor: '#EDF9FB' }}>
        <Typography variant="h4" gutterBottom>
          Arteak CV Converter
        </Typography>
        {!isLoadingFormat && (
          <Box sx={{ mt: 4 }} {...getRootProps()}>
            <CloudUploadIcon sx={{ fontSize: 80, color: 'primary.main' }} />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Click and Choose File
            </Typography>
            <input {...getInputProps()} type="file" accept=".docx,.pdf,.rtf" style={{ display: 'none' }} />
            <Typography variant="body2" sx={{ mt: 2 }}>
              or drop a file here
            </Typography>
          </Box>
        )}
        {isLoadingFormat && (
          <Box sx={{ mt: 4 }}>
            <CircularProgress />
            <Typography variant="body1" sx={{ mt: 2 }}>
              Converting to Arteak format...
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              This might take up to 1 minute
            </Typography>
          </Box>
        )}
        {alert && (
          <Alert severity={alert.type} sx={{ mt: 2 }}>
            {alert.message}
          </Alert>
        )}
      </Paper>
    </Container>
  );
};

export default CVFormatPage;