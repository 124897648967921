import axios from 'axios';

//const API_BASE_URL = 'http://127.0.0.1:5000'; // Update with your Flask app's URL
//const API_BASE_URL = 'http://TestEnv.eba-uhxhn48g.eu-south-1.elasticbeanstalk.com'
const API_BASE_URL = 'https://api.cvsearch-arteakltd.com'
//const API_BASE_URL = process.env.REACT_APP_API_URL;

const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const login = async (username, password) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/login`, { username, password }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const { token } = response.data;
    localStorage.setItem('token', token);
    return { success: true };
  } catch (error) {
    throw new Error('Login failed');
  }
};

export const changePassword = async (username, oldPassword, newPassword) => {
  try {
    await axios.post(`${API_BASE_URL}/api/change-password`, {
      username,
      old_password: oldPassword,
      new_password: newPassword,
    }, {
      headers: {
        ...getAuthHeader(),
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    throw new Error('Failed to change password');
  }
};

export const searchCvs = async (query, levels, category, page, pageSize) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/search-cvs`, {
      query: query,
      levels: levels,
      category: category,
      page: page,
      page_size: pageSize,
    }, {
      headers: {
        ...getAuthHeader(),
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching CVs');
  }
};

export const downloadCv = async (filename, level) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/download-cv`, { filename, level }, {
      headers: {
        ...getAuthHeader(),
        'Content-Type': 'application/json',
      },
      responseType: 'json',
    });
    return response.data.url;
  } catch (error) {
    throw new Error('Error getting download URL');
  }
};

export const fetchLevelCounts = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/get-level-counts`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching level counts');
  }
};

export const fetchEvaluationFiles = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/evaluation-files`, {
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching evaluation files');
  }
};

export const runEvaluation = async (query, jobTitle, certificates, level, numCvs, category) => {
  try {
    await axios.post(`${API_BASE_URL}/api/run-evaluation`, {
      query : query,
      job_title: jobTitle,
      certificates: certificates,
      level: level,
      num_cvs: numCvs,
      category: category,
    }, {
      headers: {
        ...getAuthHeader(),
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    if (error.response && error.response.status === 404) {
      throw new Error('No relevant CVs found');
    }
    throw new Error('Error running evaluation');
  }
};


export const fetchEvaluationScores = async (compositeKey) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api/evaluation-scores`, {
      params: {
        composite_key: compositeKey,
      },
      headers: getAuthHeader(),
    });
    return response.data;
  } catch (error) {
    throw new Error('Error fetching evaluation scores');
  }
};

export const formatCv = async (cvFile) => {
  try {
    const formData = new FormData();
    formData.append('cv_file', cvFile);

    const response = await axios.post(`${API_BASE_URL}/api/format-cv`, formData, {
      headers: {
        ...getAuthHeader(),
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'arraybuffer',
      timeout: 600000,
    });
    return response.data;
  } catch (error) {
    throw new Error('Error formatting CV');
  }
};


export const updateCandidateStatus = async (candidateId, category, notes) => {
  try {
    await axios.put(`${API_BASE_URL}/api/candidates/${candidateId}/status`, {
      category,
      notes,
    }, {
      headers: {
        ...getAuthHeader(),
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    throw new Error('Error updating candidate status');
  }
};

export const deleteCandidate = async (candidateId) => {
  try {
    await axios.post(`${API_BASE_URL}/api/candidates/${candidateId}/delete`, {}, {
      headers: {
        ...getAuthHeader(),
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    throw new Error('Error deleting candidate');
  }
};

export const updateCandidateLevel = async (candidateId, level) => {
  try {
    await axios.put(`${API_BASE_URL}/api/candidates/${candidateId}/level`, {
      level,
    }, {
      headers: {
        ...getAuthHeader(),
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    throw new Error('Error updating candidate level');
  }
};