// AppHeader.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const AppHeader = ({ onLogout }) => {
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#21618C' }}>
      <Toolbar>
        <img src="/Arteak_icon.png" alt="Logo" style={{ height: 40, marginRight: 16 }} />
        <Button
          component={RouterLink}
          to="/"
          color="inherit"
          sx={{
            mr: 2,
            fontWeight: isActive('/') ? 'bold' : 'normal',
            backgroundColor: isActive('/') ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
            },
          }}
        >
          Home
        </Button>
        <Button
          component={RouterLink}
          to="/evaluations"
          color="inherit"
          sx={{
            mr: 2,
            fontWeight: isActive('/evaluations') ? 'bold' : 'normal',
            backgroundColor: isActive('/evaluations') ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
            },
          }}
        >
          NEW ROLE
        </Button>
        <Button
          component={RouterLink}
          to="/format"
          color="inherit"
          sx={{
            fontWeight: isActive('/format') ? 'bold' : 'normal',
            backgroundColor: isActive('/format') ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
            },
          }}
        >
          Format
        </Button>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />
        <Button color="inherit" onClick={onLogout} startIcon={<LogoutIcon />}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;