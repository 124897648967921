import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  IconButton,
  TableSortLabel,
  useMediaQuery,
  useTheme,
  Button,
  Grid,
  Typography,
  Divider,
  TextField,
  Link,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { downloadCv, updateCandidateStatus, deleteCandidate, updateCandidateLevel } from '../api';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';


// Define styled components
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(4),
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)',
  borderRadius: theme.spacing(1),
  width: '100%',
  overflowX: 'auto',
  position: 'relative',
  maxHeight: 'calc(100vh - 200px)',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    '& th, & td': {
      padding: theme.spacing(0.5, 1),
      whiteSpace: 'nowrap',
    },
    '& th': {
      position: 'sticky',
      top: 0,
      zIndex: 1,
      backgroundColor: '#85929E',
    },
  },
}));

const StyledTableHeader = styled(TableRow)(({ theme }) => ({
  '& th': {
    backgroundColor: '#4c7a92',
    //backgroundColor: '#AEB6BF',
    color: 'white',
    fontWeight: 'bold',
    padding: theme.spacing(1.3, 1.3),
    position: 'sticky',
    top: 0,
    zIndex: 1,
    textAlign: 'center',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.action.hover,
  },
  '& td': {
    padding: theme.spacing(1.3, 1.3),
    whiteSpace: 'nowrap',
    borderBottom: `1px solid ${theme.palette.divider}`,
    textAlign: 'center',
  },
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  maxHeight: 'auto', // Set a maximum height for the dialog content
  overflowY: 'auto', // Enable vertical scrolling if the content exceeds the maximum height
  overflowX: 'auto', // Disable horizontal scrolling
  padding: theme.spacing(0), // Add some padding to the dialog content
}));

const CVTable = ({ cvs, totalCount, currentPage, pageSize, onPageChange, updateCVData }) => {
  const [selectedCV, setSelectedCV] = useState(null);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [selectedNotes, setSelectedNotes] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState('');
  const [isSavingLevel, setIsSavingLevel] = useState(false);

  useEffect(() => {
    if (selectedCV) {
      setSelectedCategory(selectedCV.category || '');
      setSelectedNotes(selectedCV.notes || '');
      setSelectedLevel(selectedCV.level || '');
    }
  }, [selectedCV]);

  const handleLevelChange = (event) => {
    setSelectedLevel(event.target.value);
  };

  const handleSaveLevelChange = async () => {
    setIsSavingLevel(true);
    try {
      await updateCandidateLevel(selectedCV.candidate_id, selectedLevel);
      // Update the CV data in the `cvs` array
      const updatedCVs = cvs.map((cv) => {
        if (cv.candidate_id === selectedCV.candidate_id) {
          return {
            ...cv,
            level: selectedLevel,
          };
        }
        return cv;
      });
      updateCVData(updatedCVs);
      setSelectedCV(null);
    } catch (error) {
      console.error('Error updating candidate level:', error);
    }
    setIsSavingLevel(false);
  };
  
  const handleNotesChange = (event) => {
    setSelectedNotes(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  
  const handleSaveChanges = async () => {
    setIsSaving(true);
    try {
      await updateCandidateStatus(selectedCV.candidate_id, selectedCategory, selectedNotes);
      // Update the CV data in the `cvs` array
      const updatedCVs = cvs.map((cv) => {
        if (cv.candidate_id === selectedCV.candidate_id) {
          return {
            ...cv,
            category: selectedCategory,
            notes: selectedNotes,
          };
        }
        return cv;
      });
      updateCVData(updatedCVs);
      setSelectedCV(null);
    } catch (error) {
      console.error('Error updating candidate status:', error);
    }
    setIsSaving(false);
  };

  const handleDownload = async (cv) => {
    try {
      const url = await downloadCv(cv.filename, cv.level);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error downloading CV:', error);
    }
  };

  const handleClose = () => {
    setSelectedCV(null);
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  const sortedCvs = orderBy
    ? [...cvs].sort((a, b) => {
        const valueA = parseInt(a[orderBy], 10);
        const valueB = parseInt(b[orderBy], 10);
        if (isNaN(valueA) && isNaN(valueB)) {
          // Both values are not numbers, compare as strings
          return order === 'asc'
            ? a[orderBy].localeCompare(b[orderBy])
            : b[orderBy].localeCompare(a[orderBy]);
        } else if (isNaN(valueA)) {
          // Only valueA is not a number, treat it as smaller
          return order === 'asc' ? -1 : 1;
        } else if (isNaN(valueB)) {
          // Only valueB is not a number, treat it as smaller
          return order === 'asc' ? 1 : -1;
        } else {
          // Both values are numbers, compare as integers
          return order === 'asc' ? valueA - valueB : valueB - valueA;
        }
      })
    : cvs;

  const handlePageChange = (newPage) => {
    onPageChange(newPage);
  };

  const handleDeleteCandidate = async (candidateId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this CV?');
    
    if (confirmDelete) {
      try {
        await deleteCandidate(candidateId);
        setSelectedCV(null);
        updateCVData(cvs.filter((cv) => cv.candidate_id !== candidateId));
      } catch (error) {
        console.error('Error deleting candidate:', error);
      }
    }
  };

  return (
    <>
      <StyledTableContainer component={Paper}>
        <Table>
          <TableHead>
          <StyledTableHeader>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'Name'}
                direction={orderBy === 'Name' ? order : 'asc'}
                onClick={() => handleSort('Name')}
              >
                Name
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'HSEExperience'}
                direction={orderBy === 'HSEExperience' ? order : 'asc'}
                onClick={() => handleSort('HSEExperience')}
              >
                HSE Position
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'OilAndGasExperience'}
                direction={orderBy === 'OilAndGasExperience' ? order : 'asc'}
                onClick={() => handleSort('OilAndGasExperience')}
              >
                O&amp;G
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'ConstructionExperience'}
                direction={orderBy === 'ConstructionExperience' ? order : 'asc'}
                onClick={() => handleSort('ConstructionExperience')}
              >
                Construction industry
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'InternationalExperience'}
                direction={orderBy === 'InternationalExperience' ? order : 'asc'}
                onClick={() => handleSort('InternationalExperience')}
              >
                International Projects
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'LargeCompanyExperience'}
                direction={orderBy === 'LargeCompanyExperience' ? order : 'asc'}
                onClick={() => handleSort('LargeCompanyExperience')}
              >
                Large Projects/Companies
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'HSEAuditorExperience'}
                direction={orderBy === 'HSEAuditorExperience' ? order : 'asc'}
                onClick={() => handleSort('HSEAuditorExperience')}
              >
                HSE Auditor
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'ArteakExperience'}
                direction={orderBy === 'ArteakExperience' ? order : 'asc'}
                onClick={() => handleSort('ArteakExperience')}
              >
                Arteak Company
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={orderBy === 'level'}
                direction={orderBy === 'level' ? order : 'asc'}
                onClick={() => handleSort('level')}
              >
                Level
              </TableSortLabel>
            </TableCell>
            {cvs && cvs.some((cv) => cv.hasOwnProperty('rank')) && (
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'rank'}
                  direction={orderBy === 'rank' ? order : 'asc'}
                  onClick={() => handleSort('rank')}
                >
                  Keywords Rank
                </TableSortLabel>
              </TableCell>
            )}
          </StyledTableHeader>
          </TableHead>
          <TableBody>
            {cvs.length > 0 ? (
              sortedCvs.map((cv, index) => (
                <StyledTableRow key={index} onClick={() => setSelectedCV(cv)}>
                  <TableCell>{cv.Name || 'N/A'}</TableCell>
                  <TableCell>{Number(cv['HSEExperience']) || 0}</TableCell>
                  <TableCell>{Number(cv['OilAndGasExperience']) || 0}</TableCell>
                  <TableCell>{Number(cv['ConstructionExperience']) || 0}</TableCell>
                  <TableCell>{Number(cv['InternationalExperience']) || 0}</TableCell>
                  <TableCell>{Number(cv['LargeCompanyExperience']) || 0}</TableCell>
                  <TableCell>{Number(cv['HSEAuditorExperience']) || 0}</TableCell>
                  <TableCell>{Number(cv['ArteakExperience']) || 0}</TableCell>
                  <TableCell>{cv['level'] || "N/A"}</TableCell>
                  {cv.hasOwnProperty('rank') && <TableCell>{cv.rank}</TableCell>}
                </StyledTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={isMobileScreen ? 5 : 9} align="center">
                  No CVs found.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', marginRight: '5px'}}>
          <Typography variant="body2">
            Showing {(currentPage - 1) * pageSize + 1}-{Math.min(currentPage * pageSize, totalCount)} of {totalCount} CVs
          </Typography>
          <div>
          {currentPage > 1 && (
            <Link
              component="button"
              variant="body2"
              onClick={() => handlePageChange(currentPage - 1)}
              style={{ marginRight: '16px' }}
            >
              Previous
            </Link>
          )}
          {currentPage < Math.ceil(totalCount / pageSize) && (
            <Link
              component="button"
              variant="body2"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </Link>
          )}
          </div>
        </div>
      </div>
      {selectedCV && (
        <Dialog open={Boolean(selectedCV)} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => handleDownload(selectedCV)}
            aria-label="download"
          >
            <DownloadIcon />
          </IconButton>
            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent style={{ overflowX: 'hidden' }}>
          <StyledDialogContent>
            <Grid container spacing={4}>
              {/* Contact Details */}
              <Grid item xs={12} md={6}>
                <Typography variant="h6">Contact Details</Typography>
                <DialogContentText>
                  <strong>Name:</strong> {selectedCV.Name}
                </DialogContentText>
                <DialogContentText>
                  <strong>Email:</strong> {selectedCV.Email}
                </DialogContentText>
                <DialogContentText>
                  <strong>Phone:</strong> {selectedCV.Phone}
                </DialogContentText>
                <DialogContentText>
                  <strong>Location:</strong> {selectedCV.Location}
                </DialogContentText>
                <DialogContentText>
                  <strong>Nationality:</strong> {selectedCV.Nationality || 'N/A'}
                </DialogContentText>
              </Grid>

              {/* Education */}
              <Grid item xs={12} md={6}>
                <Typography variant="h6">Education</Typography>
                <DialogContentText>
                  <strong>English Level:</strong> {selectedCV.English}
                </DialogContentText>
                <DialogContentText>
                  <strong>Diploma:</strong> {selectedCV.Diploma}
                </DialogContentText>
                <DialogContentText>
                  <strong>Bachelor Degree:</strong> {selectedCV['BachelorDegree']}
                </DialogContentText>
              </Grid>

              {/* Certificates */}
              <Grid item xs={12} md={6}>
                <Typography variant="h6">Required Certificates</Typography>
                <DialogContentText>
                  <strong>OHS National Certificate:</strong> {selectedCV['OHSCertificate']}
                </DialogContentText>
                <DialogContentText>
                  <strong>NEBOSH:</strong> {selectedCV['NEBOSHCertificate']}
                </DialogContentText>
              </Grid>

              {/* Required Training */}
              <Grid item xs={12} md={6}>
                <Typography variant="h6">Required Training</Typography>
                <DialogContentText>
                  <strong>Confined Space:</strong> {selectedCV['ConfinedSpaceTraining']}
                </DialogContentText>
                <DialogContentText>
                  <strong>Work at Height:</strong> {selectedCV['WorkAtHeightTraining']}
                </DialogContentText>
                <DialogContentText>
                  <strong>IOGP:</strong> {selectedCV['IOGPKnowledge']}
                </DialogContentText>
              </Grid>

              {/* Other Skills */}
              <Grid item xs={12}>
                <Typography variant="h6">Additional Information</Typography>
                <DialogContentText>
                  <strong>Skills:</strong> {selectedCV['RelevantSkills'] || 'N/A'}
                </DialogContentText>
                <DialogContentText>
                  <strong>Certifications:</strong> {selectedCV.Certifications || 'N/A'}
                </DialogContentText>
                <DialogContentText>
                  <strong>Companies:</strong> {selectedCV.Companies || 'N/A'}
                </DialogContentText>
                <DialogContentText>
                  <strong>Other Languages:</strong> {selectedCV['OtherLanguages'] || 'N/A'}
                </DialogContentText>
              </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
            <Typography variant="subtitle2" sx={{ mr: 2 }}>Level:</Typography>
              <Select
              value={selectedLevel}
              onChange={handleLevelChange}
              displayEmpty
              sx={{ minWidth: 200, marginRight: (theme) => theme.spacing(2) }}
            >
              <MenuItem value="" disabled>
                Select level
              </MenuItem>
              <MenuItem value="PM">PM</MenuItem>
              <MenuItem value="RC">RC</MenuItem>
              <MenuItem value="ADV5">ADV5</MenuItem>
              <MenuItem value="ADV4">ADV4</MenuItem>
              <MenuItem value="ADV3">ADV3</MenuItem>
              <MenuItem value="ADV2">ADV2</MenuItem>
              <MenuItem value="ADV1">ADV1</MenuItem>
              <MenuItem value="NA">NA</MenuItem>
            </Select>
            {selectedLevel !== selectedCV.level && (
              <SaveIcon
                sx={{
                  marginLeft: (theme) => theme.spacing(1),
                  cursor: 'pointer',
                  '&:hover': {
                    color: (theme) => theme.palette.primary.main,
                  },
                }}
                onClick={handleSaveLevelChange}
                disabled={isSavingLevel}
                fontSize="large"
              />
            )}
            </div>
            <Divider sx={{ my: 3 }} />
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
            <Typography variant="subtitle2" sx={{ mr: 2 }}>Category:</Typography>
              <Select
                labelId="category-label"
                value={selectedCategory}
                onChange={handleCategoryChange}
                displayEmpty
                sx={{ minWidth: 200, marginRight: (theme) => theme.spacing(2) }}
              >
                <MenuItem value="" disabled>
                  Select category
                </MenuItem>
                <MenuItem value="Potential">Potential</MenuItem>
                <MenuItem value="Prequalified">Prequalified</MenuItem>
                <MenuItem value="Qualified">Qualified</MenuItem>
                <MenuItem value="Engaged">Engaged</MenuItem>
                <MenuItem value="On Hold">On Hold</MenuItem>
                <MenuItem value="Terminated">Terminated</MenuItem>
              </Select>
              {(selectedCategory !== selectedCV.category) && (
                <SaveIcon
                  sx={{
                    marginLeft: (theme) => theme.spacing(1),
                    cursor: 'pointer',
                    '&:hover': {
                      color: (theme) => theme.palette.primary.main,
                    },
                  }}
                  onClick={handleSaveChanges}
                  disabled={isSaving}
                  fontSize="large"
                />
              )}
            </div>
            <Divider sx={{ my: 3 }} />
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
                <TextField
                  label="Notes"
                  value={selectedNotes}
                  onChange={handleNotesChange}
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  sx={{ marginRight: (theme) => theme.spacing(2) }}
                />
                {selectedNotes !== (selectedCV.notes || '')  && (
                  <SaveIcon
                    sx={{
                      marginLeft: (theme) => theme.spacing(1),
                      cursor: 'pointer',
                      '&:hover': {
                        color: (theme) => theme.palette.primary.main,
                      },
                    }}
                    onClick={handleSaveChanges}
                    disabled={isSaving}
                    fontSize="large"
                  />
                )}
              </div>
              <Divider sx={{ my: 3 }} />
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleDeleteCandidate(selectedCV.candidate_id)}
                  startIcon={<DeleteIcon />}
                >
                  Delete CV
                </Button>
              </div>
            </StyledDialogContent>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default CVTable; 